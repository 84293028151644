import React, { useState } from "react"
import CookieConsent from "react-cookie-consent";
import "../styles/main.scss"
import PreHeader from "./preHeader"
import Footer from "./footer"
import { translate } from "../utils"
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';


export default function Layout({ withBg = false, children ,ClassIndex="", hideHeader=false}) {
  const [isRendered, setisRendered] = useState(false);
  if (!isRendered) {
    if (typeof window !== "undefined") {
      const tagManagerArgs = {
        gtmId: 'GTM-N8PSVKX'
      }

      TagManager.initialize(tagManagerArgs)
    }
    ReactGA.initialize('G-1D942S8R6N');
    setisRendered(true)
  }
  return (
    <div className={`flexBody ${withBg ? "arrow-bg" : ""}`}>
      {(!hideHeader)?
      <PreHeader />
      :""
      }
      <div className={`pageContent ${ClassIndex} newindex`}>{children}</div>
      <CookieConsent
        location="bottom"
        buttonText={"_"+translate('aanvaarden')}
        cookieName="coockiesUserAccepted"
        style={{ background: "" }}
        buttonStyle={{ }}
        expires={3}
      >
        {translate("deze website maakt gebruik van cookies om uw surfervaring te verbeteren")}.{" "}
   
      </CookieConsent>
      {/*<CookieNotice
        acceptButtonLabel={'Aanvaarden'}
        readMoreButtonLabel={'Lees verder'}
        cookieTextLabel={'Deze website maakt gebruik van cookies om uw surfervaring te verbeteren.'}
        cookieExpiration={30}
        cookieName={'coockiesUserAccepted'}
        darkTheme={true}
      />*/}
      {/* <Footer /> */}
    </div>
  )
}
